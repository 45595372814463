<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card max-width="600" grid-list-lg>
      <ModelTitle title="Receive Payment" @close="close()" />

      <v-card-text>
        <v-container class="pa-0" grid-list-lg>
          <v-card-text>
            <v-layout row wrap justify-space-between>
              <v-flex v-if="invoiceReceivePayment && Payment.PaymentDetails.length > 0" xs6>
                <div>Invoice ID</div>
                <div class="font-weight-medium title">
                  {{ Payment.PaymentDetails[0].localId }}
                </div>
              </v-flex>
              <v-flex v-if="invoiceReceivePayment && Payment.PaymentDetails.length > 0" shrink>
                <div>Invoice Due</div>
                <div class="font-weight-medium title">
                  {{ Payment.PaymentDetails[0].amount | currency }}
                </div>
              </v-flex>
              <v-flex xs6>
                <div>Customer ID</div>
                <div class="font-weight-medium title">
                  {{ Customer.localId }}
                </div>
              </v-flex>
              <v-flex shrink v-if="!invoiceReceivePayment">
                <div>Balance</div>
                <div class="font-weight-medium title">
                  {{ Customer.balance | currency }}
                </div>
              </v-flex>
              <v-flex xs8>
                <div>Customer Name</div>
                <div class="font-weight-medium title text-capitalize">
                  {{ Customer.name }}
                </div>
              </v-flex>
              <v-flex shrink>
                <div>Paying</div>
                <div class="font-weight-medium title">
                  <span v-if="!invoiceReceivePayment">{{ total | currency }}</span>
                  <span v-else>{{ InvoiceTotal | currency }}</span>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row wrap justify-space-between>
              <v-flex xs12 sm4>
                <DateSelector label="Payment Date" v-model="Payment.date" />
              </v-flex>

              <v-flex xs12 sm4>
                <v-select dense outlined :items="paymentMethods" v-model="Payment.paymentMethod" label="Payment Method"
                  item-text="name" hide-details return-object></v-select>
              </v-flex>

              <v-flex xs12 sm4>
                <v-text-field autofocus type="number" dense outlined class="right-input" hide-details label="Total Payment"
                  @focus="$event.target.select()" v-model="InvoiceTotal"></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field dense outlined v-model="Payment.note" label="Memo" hide-details></v-text-field>
              </v-flex>
            </v-layout>
            <div class="text-center">
              <span v-if="!invoiceReceivePayment">Balance: {{ balance | currency }}/ Total:
                {{ InvoiceTotal | currency }}/ Payment Receiving:
                {{ total | currency }}</span>


            </div>
          </v-card-text>
          <v-card :color="color" :disabled="InvoiceTotal === 0" outlined v-if="Invoice && !invoiceReceivePayment">
            <v-card-text>
              <v-layout row wrap align-center>
                <v-flex xs7>
                  <v-select clearable dense outlined :items="Invoice" hide-details label="Select Invoice"
                    item-text="localId" return-object v-model="selectedInvoice">
                    <template slot="item" slot-scope="{ item }">
                      <span>{{ item.localId }}</span>
                      <v-spacer></v-spacer>
                      <span>{{ item.due | currency }}</span>
                    </template>
                    <template v-slot:append-item v-if="Invoice.length !== 0">
                      <v-divider class="mt-2"></v-divider>
                      <v-list-item @click="payAll">
                        <v-list-item-content>
                          <v-list-item-title>Receive All</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          {{ InvoiceTotal | currency }}
                        </v-list-item-icon>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs3>
                  <v-text-field type="number" dense outlined class="right-input" hide-details label="Payment"
                    @focus="$event.target.select()" v-model="amount"></v-text-field>
                </v-flex>
                <v-flex shrink>
                  <v-btn @click="add" color="success"> add </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <v-list v-if="!invoiceReceivePayment" class="list-350">
            <template v-for="(row, index) in Payment.PaymentDetails">
              <v-list-item :key="row.id">
                <v-list-item-avatar>
                  <v-btn icon text color="red" @click="remove(index)"><v-icon>delete</v-icon></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ row.localId }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <span class="title">{{ row.amount | currency }}</span>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index + 1 < Payment.PaymentDetails.length" :key="index">
              </v-divider>
            </template>
          </v-list>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text @click="close()">Close</v-btn>
        <v-btn color="info" v-if="!invoiceReceivePayment" :disabled="Payment.PaymentDetails.length === 0"
          :loading="loading" @click="submit()">{{
            total |
            currency }} Save</v-btn>
        <v-btn color="info" v-if="invoice" :disabled="InvoiceTotal > invoice.due" :loading="loading"
          @click="submit()">{{
            InvoiceTotal |
            currency }} Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import invoiceService from "@/modules/Invoice/service";
import customerService from "@/modules/Customer/service";
import paymentService from "../service";
import DateSelector from "@/components/DateSelector";

export default {
  name: "create-payment",
  props: {
    createDialog: {
      default: false,
    },
    CustomerId: {
      default: null,
    },
    invoice: {
      default: null,
    },
    invoiceReceivePayment: {
      default: false,
    },
  },
  components: {
    DateSelector,
  },
  data() {
    return {
      InvoiceTotal: 0,
      selectedInvoice: null,
      Invoice: null,
      loading: false,
      dialog: false,
      amount: 0,
      Customer: {
        name: null,
        balance: 0,
        localId: null,
      },
      paymentMethods: [
        "Cash",
        "Check",
        "Paypal",
        "Behalf",
        "COD",
        "Debit Card",
        "Credit Card",
        "Wire",
        "Quick Pay",
        "Cash App",
        "Exchange",
        "Other",
      ],
      Payment: {
        CustomerId: 0,
        date: this.$moment().toISOString(),
        paymentMethod: "Cash",
        PaymentDetails: [],
      },
    };
  },
  computed: {
    color() {
      let value = "indigo lighten-5";
      if (this.balance === 0) {
        value = "green lighten-5";
      } else {
        if (this.balance < 0) {
          value = "red lighten-5";
        } else {
          value = "indigo lighten-5";
        }
      }

      return value;
    },
    total() {
      let value = 0;
      this.Payment.PaymentDetails.map((row) => {
        value += parseFloat(row.amount);
      });
      return value;
    },
    balance() {
      return this.InvoiceTotal - parseFloat(this.total);
    },
  },
  watch: {
    selectedInvoice(val) {
      if (this.balance > val.due) {
        this.amount = val.due;
      } else {
        if (this.balance <= 0) {
          this.amount = val.due;
        } else {
          this.amount = this.balance;
        }
      }
    },

    createDialog(val) {
      this.dialog = val;
      if (val) {
        // if (this.invoice) {
        //   let invoicePayment = this.invoice
        //   invoicePayment.amount = this.invoice.due;
        //   invoicePayment.InvoiceId = invoicePayment.id;
        //   this.Payment.PaymentDetails.push(invoicePayment);
        // }
        if (!this.invoice)
          this.loadOpenInvoice();
        this.getCustomer();
      }
    },
  },
  methods: {
    async payAll() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to select all the invoices for ${this.$options.filters.currency(
          this.InvoiceTotal
        )}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          await Promise.all(
            this.Invoice.map((row) => {
              let x = row;
              x.amount = row.due;
              x.InvoiceId = row.id;
              this.Payment.PaymentDetails.push(row);
            })
          );
          this.Invoice = [];
          this.emptybar();
        }
      });
    },
    add() {
      if (this.amount === 0) return false;
      const index = this.Invoice.findIndex(
        (x) => x.id === this.selectedInvoice.id
      );
      let row = this.Invoice[index];

      if (this.amount > this.Invoice[index].due) {
        this.$swal({
          title: "Exceeded!",
          text: `Payment can't be more then ${this.Invoice[index].due}`,
          icon: "warning",
        });
      } else {
        row.amount = this.amount;
        row.InvoiceId = row.id;
        this.Payment.PaymentDetails.push(row);
        this.Invoice.splice(index, 1);
        this.amount = 0;
      }
    },
    emptybar() {
      this.amount = 0;
      this.selectedInvoice = null;
    },
    remove(i) {
      this.Invoice.push(this.Payment.PaymentDetails[i]);
      this.Payment.PaymentDetails.splice(i, 1);
    },
    getCustomer() {
      return customerService.getById(this.CustomerId).then((response) => {
        this.Customer = response.data;
        this.Payment.CustomerId = this.Customer.id;
        // if (this.invoice) {

        //   this.InvoiceTotal = this.invoice.due
        // }
        return response;
      });
    },
    loadOpenInvoice() {
      this.loading = true;
      return invoiceService
        .getAll({ due: true, CustomerId: this.CustomerId })
        .then((response) => {
          this.loading = false;
          this.Invoice = response.data;

          this.Invoice.map((row) => {
            this.InvoiceTotal += row.due;
          });
          return response;
        });
    },
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.InvoiceTotal = 0;
      this.Payment = {
        CustomerId: 0,
        date: this.$moment().toISOString(),
        paymentMethod: "Cash",
        PaymentDetails: [],
      };
    },
    submit() {
      let textValue = null
      if (!this.invoiceReceivePayment) {
        textValue = `You are receiving ${this.$options.filters.currency(
          this.total
        )}? `
      } else {
        textValue = `You are receiving ${this.$options.filters.currency(
          this.InvoiceTotal
        )}? `
      }
      if (this.invoice) {
          let invoicePayment = this.invoice
          invoicePayment.amount = this.InvoiceTotal
          invoicePayment.InvoiceId = invoicePayment.id;
          this.Payment.PaymentDetails.push(invoicePayment);
        }
      this.$swal({
        title: "Saving Payment",
        text: textValue,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          // this.$Progress.start();
          this.loading = true;
          console.log('this.Payment', this.Payment)
          return paymentService
            .create(this.Payment)
            .then((result) => {
              this.$events.emit("CustomerMiniList");
              if (result.status) {
                this.$swal
                  .mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  .fire({
                    icon: "success",
                    title: "Payment was made",
                  });
                this.loading = false;
                this.empty();
                this.$emit("submit");
                this.$events.emit("paymentListEvent");
                this.$events.emit("customerProfileEvent");
                this.$events.emit("invoiceListEvent");
                this.close();
              }
            })
            .catch((err) => {
              // console.log("jerror", err);
              this.loading = false;
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "error",
                  title: err.data.message,
                });
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
